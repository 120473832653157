import React, {Component} from "react";
import {request} from "../../../api-client";
import {makeImageUrlWithSize, emptyImage} from "../HomeElements/utils";
import PropTypes from "prop-types";
import browserHistory from "../../../utils/browserHistory";
import * as VCS from "../../../components/Letflow/ContentCreatorViewComponents";
import localization from "../../../config/localization"
import Slider from "react-slick/lib";
import TrackResultWithPagination from "../../../components/Letflow/TrackResultWithPagination";
import TrackTable from "../../../components/Letflow/TrackTable";
import HomeItemDescription from "../HomeElements/HomeItemDescription";
import './Artist.css'
import {
  catalogClientView, channelCanAddToFavorites, channelPathGenerator, getActiveChannel,
  getActiveClient, getStoredUser, getStoredChannel, setPageTitle
} from "../../../api-client/core/authentication/utils";
import EventManager, {events} from "../../../utils/EventManager";
import moment from "moment";
import ProfilePicture from "../../../assets/img/default-avatar.png";
import {makeFitImageUrl} from "../../../utils";
import Logo from "../../../assets/img/logo-black.png";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import PrevArrow from "../../../components/Letflow/Arrows/PrevArrow";
import NextArrow from "../../../components/Letflow/Arrows/NextArrow";

export default class Artist extends Component {
  state = {
    artist: null,
    albums: [],
    selectedVideo: null,
    musics: [],
    favorite: false,
    height: "175px",
    descriptionMaxWidth: '58%',
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.state.artistId != nextProps.match.params.artistId) {
      this.fetchArtist().then(() => this.requestTracks());
    }

    getActiveClient() && request.client.get(getActiveClient()).then(response => this.setState({client: response}))
  }

  componentDidMount = () => {
    this.fetchArtist().then(() => this.requestTracks());

    if(!this.props.modal) {
      window.scrollTo(0,0);
    }
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavorites);
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavorites);
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavorites);
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavorites);
  };

  requestFavorites = () => request.favorite.getAll().then((albumsFavorites) => this.setState({ albumsFavorites }))

  addToFavorites = ({id, type}) => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      this.setState({favorite: true});
      if (!getActiveChannel()) {
        request.favorite.add(id).catch(() => this.setState({favorite: false}));
      } else {
        request.artistChannelFavorite.add(id).catch(() => this.setState({favorite: false}));
      }
    }
  };

  removeFromFavorites = ({id, type}) => {
    this.setState({ favorite: false });
    if(!getActiveChannel()) {
      request.favorite.remove(id).catch(() => this.setState({ favorite: true }));
    }else {
      request.artistChannelFavorite.remove(id).catch(() => this.setState({ favorite: true }));
    }
  };

  fetchArtist = () => {
    const type = getActiveClient() ? 'getByClient' : 'get'

      return request.artist[type](this.props.match.params.artistId || this.props.artistId, `include=image;profile;albums.image;albums.artist;albums.favorite;${getActiveChannel() ? "channelFavorite" : "favorite"};country;socialLinks`)
        .then(artist => {
          setPageTitle(artist.name)
          this.setState({
            artist: {
              id: artist.id,
              name: artist.name,
              description: artist.description,
              imageUrl: makeImageUrlWithSize(artist, 'lg'),
              country: artist.country,
              profileUrl: artist.profile && makeImageUrlWithSize({image: artist.profile}, 'sm'),
              birthdate: artist.birthdate,
              socialLinks: artist.socialLinks
            },
            favorite: getActiveChannel ? artist.channelFavorite : artist.favorite,
            albums: artist.albums,
            artistId: artist.id
          });
        });
  }

  requestTracks = ({clientId = getActiveClient(), artistId = this.props.match.params.artistId || this.props.artistId, page = 0, perPage = 12} = {}) => {
    if (clientId) {
      return request.music.getAllByArtist(
        clientId,
        artistId,
        {
          page,
          perPage
        }
      ).then(musics => this.setState({musics}))
    }
    return request.music.getAllByArtistPublic(
      artistId,
      {
        page,
        perPage
      }
    ).then(musics => this.setState({musics}))
  }

  makeAlbumDescription = () => {
    if (this.state.selectedAlbum) {
      return <div className="artist-musics-container">
        <HomeItemDescription
          {...this.props}
          loading={this.state.loading}
          data={this.state.selectedAlbum}
          onClose={() => {
            this.setState({selectedAlbum: null})
          }}
          trackType={'music'}
          dataType={'album'}
          favorites={this.state.albumsFavorites}
          color={(getActiveChannel() && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.creation.color : "black"}
          transparentBackground
        />
      </div>
    }
  }

  selectAlbum = (album) => {
    this.setState({selectedAlbum: null}, () => {
      getActiveClient() ?
        request.music.getAllByAlbumForClient(getActiveClient(), album.id, `include=recentLicenses.user;versions_count;audio:id,duration,url;tags;${getActiveChannel() ? "channelFavorite" : "favorite"};audio.waveform;albums.image;artist`, album.type === 'library' ? 'created_at' : null, album.type === 'library' ? 'desc' : null)
          .then(musics => this.setState({
            selectedAlbum: {
              ...album,
              tracks: musics.map(music => ({ ...music, type: 'music' })),
              longDescription: album.long_description,
              shortDescription: album.short_description,
            }
          }))
          :
          request.music.showByAlbumPublic(album.id)
            .then(musics => this.setState({
              selectedAlbum: {
                ...album,
                tracks: musics.map(music => ({ ...music, type: 'music' })),
                longDescription: album.long_description,
                shortDescription: album.short_description,
              }
            })
        )
    })
  }

  toggleArtistFavorite = () => {
    if (this.state.favorite) {
      this.setState({favorite: false})
      if(!getActiveChannel()) {
        request.favorite.remove(this.state.artist.id);
      }else {
        request.artistChannelFavorite.remove(this.state.artist.id);
      }
    } else {
      this.setState({favorite: true})
      if(!getActiveChannel()) {
        request.artistFavorite.add(this.state.artist.id)
      }else {
        request.artistChannelFavorite.add(this.state.artist.id);
      }
    }
  }


  render = () => {
    if (!this.state.artist) {
      return null;
    }
    const {artist, musics, albums, height} = this.state

    return (
      <VCS.ViewContainer bgImage={artist.imageUrl}>
        <VCS.TopContainer>
          {this.props.modal &&
          <div style={
            window.innerWidth > 600 ? 
              {
                left: (catalogClientView() && this.state.width > 1200) && "66px",
                position: "absolute",
                float: "left",
                width: "auto",
                cursor: "pointer",
                top: 0,
                zIndex: 1,
              }
            :
              {
                position: "absolute",
                cursor: "pointer",
                top: 0,
                left: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }
          }>
            <div className="navbar-image-container-link" style={{width: "auto"}} onClick={() => this.props.onModalClose()}>
              {getActiveChannel() ?
                <img
                  className="navbar-image" style={{ maxWidth: "130px"}}
                  src={(getStoredChannel() && getStoredChannel().image) ?
                    makeFitImageUrl(getStoredChannel(), 300, 50, '', 'png')
                    :
                    (getStoredChannel() ?
                      makeFitImageUrl(getStoredChannel().client, 300, 50, '', 'png')
                      : Logo)}
                  alt={`Logo ${getActiveChannel() ? getStoredChannel().name : "Feater"}`}/>
                :
                <img className="navbar-image"
                     src={(this.state.client && this.state.client.use_logo && this.state.client.image) ?
                       makeFitImageUrl(this.state.client, 300, 35, '', 'png'):
                         Logo}
                     alt={`Logo ${getActiveChannel() ? getStoredChannel().name : "Feater"}`}/>
              }
            </div>
          </div>
          }
          <VCS.TopContainerInfoContainer style={{maxWidth: window.innerWidth > 600 ? '58%' : '70%'}} InfoContainer>
            <VCS.TopContainerName style={{overflow: 'hidden', textOverflow:'ellipsis'}} Name>{artist.name}</VCS.TopContainerName>
            {artist.country && <VCS.TopContainerCountry>{`${localization.get('nationality')}: ${artist.country.name}`}</VCS.TopContainerCountry>}
            {artist.birthdate && <VCS.TopContainerBirthdate>{`${localization.get('age')}: ${moment().diff(artist.birthdate, 'years')}`}</VCS.TopContainerBirthdate>}
            {artist.description &&
                <VCS.TopContainerDescription
                  text={artist.description}
                  height={height}
                  color={"#fff"}
                  onWidthChange={() => this.setState(prevState => ({...prevState, descriptionMaxWidth: prevState.descriptionMaxWidth === '58%' ? '80%' : '58%'}))}
                />
              }
          </VCS.TopContainerInfoContainer>
          <VCS.SocialBanner
            socialLinks={artist.socialLinks}
            name={artist.name}
            picture={artist.profileUrl ? artist.profileUrl : (artist.imageUrl || ProfilePicture)}
            onClose={() => !this.props.modal ? browserHistory.push(channelPathGenerator('')) : this.props.onModalClose()}
            favorite={this.state.favorite}
            onToggleFavorite={this.toggleArtistFavorite}
            showFavoriteButton={(!!getActiveClient() && !!getStoredUser()) || (getActiveChannel() && channelCanAddToFavorites())}
            showShareButton={false}
            isModal={this.props.modal}
          />
        </VCS.TopContainer>


        {albums.length > 0 &&
          <div className="artist-albums-container">
            <div className="artist-albums-title">{localization.get('albums')}</div>
            <Slider
              dots={true}
              infinite={false}
              speed={500}
              slidesToShow={3}
              slidesToScroll={3}
              initialSlide={0}
              prevArrow={<PrevArrow overrideStyle={{ color: "var(--main-font-color, #00000099)"}}/>}
              nextArrow={<NextArrow overrideStyle={{ color: "var(--main-font-color, #00000099)"}}/>}
              responsive={
                [
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2,
                    }
                  },
                ]
              }
            >
              {albums.map((album, index) => {
                return (
                  <div className="artist-album-container" onClick={() => this.selectAlbum(album)}>
                    <div className="artist-album-image-container">
                      <div className="artist-album-image" style={{backgroundImage: `url(${makeImageUrlWithSize(album, 'md')}), url(${emptyImage})`}}/>
                    </div>
                    <div style={{
                      width: albums.length === (index + 1) || index === 0 ? 'calc(100% - 10px)' : '100%',
                      float: index === 0 ? 'right' : 'left'
                    }}
                         className="artist-album-name">{album.title}
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        }
        <div>
          {this.makeAlbumDescription()}
        </div>
        <div style={{float: "left", position: "relative", width: "100%"}}>
          <VCS.WorkContainer
            WorkContainer
            shape={(getStoredChannel() && getStoredChannel().preferences && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.card.shape : "rounded"}
            title={localization.get('work')}
          >
            <div className="artist-musics-container">
              <div className="artist-musics-title" style={{color: (getActiveChannel() && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.creation.color : "black"}}>{localization.get('artist.new_releases')}</div>
              {musics.data &&
              <TrackResultWithPagination
                rows={<TrackTable {...this.props} type={'music'} hideSuggestions={true} tracks={musics} fromHome={false} transparentBackground color={(getActiveChannel() && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.creation.color : "black"} />}
                onPageChange={page => this.requestTracks({ page: page })}
                pagination={musics.meta}
              />
              }

            </div>
          </VCS.WorkContainer>
        </div>
      </VCS.ViewContainer>
    );
  };
}

Artist.propTypes = {
  id: PropTypes.number.isRequired
};

