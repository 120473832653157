import React, {Component} from "react";
import {request} from "../../../api-client";
import {isNullOrUndefined} from "util";
import {
  downloadFromUrl,
  makeFitImageUrlWithSize,
  getValueFromQueryParam,
  makeFitImageUrl
} from "../../../utils";
import browserHistory from "../../../utils/browserHistory";
import * as VCS from "../../../components/Letflow/ContentCreatorViewComponents";
import localization from "../../../config/localization";
import GlobalAddToPlaylistDialog from "../../../components/Letflow/AddToPlaylistDialog/GlobalAddToPlaylistDialog";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import {
  addHttpsToUrl,
  catalogClientView,
  channelCanAddToFavorites,
  channelPathGenerator,
  checkIfMobile,
  getActiveChannel,
  getActiveClient, getUseTools,
  getStoredChannel, getStoredUser,
  homePathGenerator, slugify, getMainTranslation, userCanEditCreators
} from "../../../api-client/core/authentication/utils";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import styled from "styled-components";
import TrackResult from "../../../components/Letflow/TrackResult";
import TrackTable from "../../../components/Letflow/TrackTable";
import HomeItemDescriptionTags from "../HomeElements/HomeItemDescriptionTags";
import Logo from "../../../assets/img/logo-black.png";
import {Dialog, DialogActions, DialogContent, IconButton, Tooltip} from "@material-ui/core";
import Contact from "../Contact/index.jsx"
import {ChevronLeft, ChevronRight, CloudDownload, PlayArrow, Redeem, Share, Edit, Star, StarBorder} from "@material-ui/icons";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import EmbededContent from "./EmbededContent";
import {Link} from "react-router-dom";
import ShakaPlayer from "../../../components/Letflow/ShakaPlayer";
import Button from "../../../components/CustomButtons/Button";
import {get} from "lodash"
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import ProductDialog from "../../../components/Letflow/ProductsDialog";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Chip from "@material-ui/core/Chip";
import font from "../HomeElements/Styles/Fonts/fonts.module.css";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import qs from 'qs';
import {Helmet} from "react-helmet/es/Helmet";
import style from "./contentCreator.module.css"
import './AudiovisualCreator.css';
import SmartSearchContentCreatorCreations from "../SmartSearch/SmartSearchContentCreatorCreations";
import RelatedContent from "../../../components/Letflow/RelatedContent/RelatedContent";
import RichEditor from "../../../components/Letflow/RichTextEditor/EditorialEditor";
import {convertFromRaw, EditorState} from "draft-js";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize, onImageError} from "../HomeElements/utils";
import download from 'downloadjs';
import EditContentCreatorDialog from "../../../components/Letflow/EditContentCreatorDialog";
import EditContentCreationDialog from "../../../components/Letflow/EditContentCreationDialog";
import {renderSocialLinksButtons} from "../../../components/Letflow/ContentCreatorViewComponents/SocialBanner";
import BuyButton from '../../../components/Letflow/BuyButton/BuyButton';

export default class AudiovisualCreator extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()

    this.storedChannel = getStoredChannel();
    let eventsPosition = this.storedChannel && this.storedChannel.preferences && this.storedChannel.preferences.content_creator && this.storedChannel.preferences.content_creator.events && this.storedChannel.preferences.content_creator.events.position;
    this.state = {
      contentCreator: props.contentCreator,
      contentCreations: props.contentCreations || [],
      selectedContentCreation: props.selectedContentCreation,
      audioContentCreationsBySelectedTags: props.audioContentCreationsBySelectedTags || [],
      contentCreationsBySelectedTags: props.contentCreationsBySelectedTags || [],
      favorite: props.favorite || false,
      tags: props.tags || [],
      autoPlay: false,
      key: 0,
      currentIndex: 0,
      showContactDialog: false,
      height: "175px",
      notAvailable: false,
      loaded: false,
      requireLogin: props.requireLogin || false,
      fullContent: false,
      openDrmDialog: false,
      drmDialogOpened: false,
      openRegionBlockDialog: false,
      trackedMainMaterialPlayed: false,
      showPreRoll: true,
      playedCreations: [],
      descriptionMaxWidth: '58%',
      showShareDialog: false,
      openProductDialog: false,
      isGift: false,
      productTargetId: null,
      productId: null,
      productReturnUrl: null,
      productName: "",
      selectedTags: [],
      search: "",
      eventsPosition: eventsPosition ? eventsPosition : 'top',
      shareUrl: null,
      checkingPurchases: false,
      purchaseCheckAttempts: 20,
      buyButtonProduct: null,
      creationsLoaded: props.creationsLoaded,
      viewType: props.viewType || 'carousel',
      showInappropriate: true,
      hideInappropriate: false,
      downloading: false,
      editContentCreatorDialogOpen: false,
      editContentCreationDialogOpen: false,
      editingContentCreation: {},
      selectedPeople: null
    };
  }


  componentDidMount = () => {
    let description = document.getElementById("audiovisual-creator-description")
    let linkList = description ? (description.querySelectorAll('a') || []) : [];

    linkList.forEach((elem) => {
      if (elem.href.startsWith(`${window.location.protocol}//${window.location.hostname}`)) {
        elem.setAttribute('target', '_self');
      } else {
        elem.setAttribute('target', '_blank');
        elem.setAttribute('rel', "nofollow");
      }
      // elem.style.color = '#00b8d6'
      elem.style.fontWeight = 'bold'
    })

    if (!this.props.isPublic) {
      if (this.props.contentCreator.hasSuggested) {
        if (this.props.contentCreator.suggestedPlaylist) {
          request.playlist.getForChannel(this.props.contentCreator.suggestedPlaylist.id, 'include=contentCreators;channel')
            .then(playlist => this.setState({playlist}))
        } else if (this.props.contentCreator.suggestedCreators) {
          this.setState({suggestedCreators: this.props.contentCreator.suggestedCreators})
        }else {
          request.contentCreator.getSuggested(this.props.contentCreator.id, this.props.contentCreator.suggestedTagGroup, true).then(suggestedCreators => this.setState({suggestedCreators}))
        }
      }
    }

    if (!this.props.isPublic && (this.props.contentCreator.buyButton || this.state.contentCreator.paymentRequired) && getStoredUser()) {
      if (get(this.props, "contentCreator.buyButton.productId")) {
        request.products.getForChannel(this.props.contentCreator.buyButton.productId)
          .then(product => this.setState({buyButtonProduct: product}));
      } else {
        request.contentCreator.isPurchased(this.state.contentCreator.id)
          .then(res => this.setState({ contentCreator: {...this.state.contentCreator, paymentRequired: !res.bought }}))
      }
    }

    this.state.creationsLoaded && this.onCreationsLoaded();

    let token = getValueFromQueryParam("token");

    if (token) this.checkPurchases(token);
    if(!this.props.modal) window.scrollTo(0,0);
  };

  onCreationsLoaded = () => {
    this.state.contentCreations.length < 1 && this.setState({height: '100%', descriptionMaxWidth:'80%'});
    this.setState({
      selectedContentCreation: this.props.selectedContentCreation,
      audioContentCreationsBySelectedTags: this.props.audioContentCreationsBySelectedTags,
      contentCreationsBySelectedTags: this.props.contentCreationsBySelectedTags,
      tags: this.props.tags || [],
      creationsLoaded: true,
    }, () => {
      if (this.props.contentCreationsBySelectedTags.length > 0 && this.state.selectedContentCreation && this.state.selectedContentCreation.blocked) {
        this.getBlockedContentCreations(this.state.selectedContentCreation, false)
      } 
      return this.handleFilters([])
    })
  }

  getBlockedContentCreations = (creation, autoPlay = this.state.autoPlay) => {
    const materialToken = sessionStorage.getItem(`mt_${creation.id}`)
    
    return request.contentCreation.get(creation.id, `include=image;video.thumbnail;embededContent;audio;binary;tags.group${materialToken ? "&materialToken="+materialToken : ""}`)
      .then(creation => {
        if(creation.tracking_token) sessionStorage.setItem(`mt_${creation.id}`, creation.tracking_token)
        return this.setState({
          autoPlay,
          contentCreationsBySelectedTags: this.state.contentCreationsBySelectedTags.map(c => c.id === creation.id ? 
            {
              ...c,
              blockedForCountry: !!creation.blocked_for_country,
              channelFavorite: creation.channelFavorite || false,
              video: creation.video,
              url: creation.video && creation.video.url,
              smallUrl: creation.video && creation.video.url + '#t=2',
              thumbnail: creation.video && (creation.image ? makeImageUrlWithSize(creation, 'lg') : makeThumbnailUrlWithSize(creation.video, 'lg')),
              requireLogin: creation.require_login,
              limited: creation.limited,
              viewLimit: creation.view_limit,
              timeToTrack: creation.time_to_track,
              onlyArgentina: creation.only_argentina,
              sentView: false,
              viewed: false,
              views: creation.views,
              trackingToken: creation.tracking_token || false,
              paymentRequired: creation.payment_required || false,
              requireAccessLevel: !!creation.require_access_level
            }
            : c)
        })
    })
  }

  
  onChangeMaterial = index => {
    if (this.state.contentCreationsBySelectedTags[index].blocked) {
      return this.getBlockedContentCreations(this.state.contentCreationsBySelectedTags[index])
        .then(() => this.setState({currentIndex: index, drmDialogOpened: false, hideInappropriate: false}))
    }
    return this.setState({currentIndex: index, drmDialogOpened: false, hideInappropriate: false})
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.contentCreatorId !== prevProps.contentCreatorId) {
      if(!this.props.modal) window.scrollTo(0,0);
      this.props.contentCreations.length < 1 && this.setState({height: '100%'});
      if (this.props.contentCreator.hasSuggested) {
        if (this.props.contentCreator.suggestedPlaylist) {
          request.playlist.getForChannel(this.props.contentCreator.suggestedPlaylist.id, 'include=contentCreators;channel').then(playlist => this.setState({playlist}))
        } else {
          request.contentCreator.getSuggested(this.props.contentCreator.id, this.props.contentCreator.suggestedTagGroup).then(suggestedCreators => this.setState({suggestedCreators}))
        }
      }
    }
    if( (this.props.creationsLoaded !== prevProps.creationsLoaded && !!this.props.creationsLoaded) ||
      (this.props.audioContentCreationsBySelectedTags.length !== prevProps.audioContentCreationsBySelectedTags.length ||
        this.props.contentCreationsBySelectedTags.length !== prevProps.contentCreationsBySelectedTags.length)
    ) {
      this.onCreationsLoaded();
    }
  }

  checkPurchases = async (token) => {
    const poll = async (fn, fnCondition, ms) => {
      try {
        let result = await fn();
        while (fnCondition(result)) {
          await wait(ms);
          result = await fn();
        }
        this.setState({checkingPurchases: false});
        return result;
      } catch (e) {
        this.setState({checkingPurchases: false})
      }
    };

    const wait = (ms = 1000) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };
    
    let checkPurchase = () => request.products.getPaypalWebhook(token);
    let validate = result => {
      if (this.state.purchaseCheckAttempts <= 0) {
        return false;
      }else {
        this.setState({purchaseCheckAttempts: this.state.purchaseCheckAttempts - 1});
        return result && result.some(t => t.status == 'pending');
      }
    };

    this.setState({checkingPurchases: true}, async () => {
      await poll(checkPurchase.bind(this), validate.bind(this), 3000);
      let content = getValueFromQueryParam("contenido");
      let url = {
        pathname: window.location.pathname
      };
      if (content) {
        url['search'] = qs.stringify({contenido: content});
      }
      browserHistory.push(url);
      window.location.reload();
    });
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (this.state.contentCreations !== nextProps.contentCreations) {
      this.setState({contentCreations: nextProps.contentCreations});
    }
  }

  getTagsFromContentCreations = (contentCreations) => {
    let tags = [];
    contentCreations.forEach(contentCreation => {
      contentCreation.tags.forEach(tag => {
        !tags.find(t => t.id === tag.id) && tags.push(tag)
      })
    });
    return tags.sort((a, b) => a.name.localeCompare(b.name));
  };

  toggleFavorite = () => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: this.storedChannel.login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator(`login?redirect_to=${this.props.modal ? window.location.pathname+window.location.search : window.location.pathname}`))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      this.setState({favorite: !this.state.favorite}, () => {
        analyticsInstance.favorite({
          name: getMainTranslation(this.state.contentCreator, "name"),
          type: "Content",
          content: this.state.favorite ? "Add" : "Remove"
        })
        
        request.contentCreatorChannelFavorite[this.state.favorite ? 'add' : 'remove'](this.state.contentCreator.id)
      })
      this.props.modal && this.props.toggleModalFavorite && this.props.toggleModalFavorite()
    }
  };

  onSelectedTagChanged = selectedTag => {
    this.setState({
      tags: this.updateTagGroups(this.state.tags, selectedTag),
      lastTagSelected: selectedTag
    }, () => this.filterContentCreationsBySelectedTags())
  }

  updateTagGroups = (tags, selectedTag) => {
    return tags.map(tag => ({
      ...tag,
      state: (
        selectedTag &&
        (selectedTag !== this.state.lastTagSelected || (tag.state === 'selected' && selectedTag === this.state.lastTagSelected)) &&
        selectedTag.toString() === tag.id.toString()
      ) ? 'selected' : 'available'
    }))
  }

  filterContentCreationsBySelectedTags = () => {
    let {contentCreations} = this.state;
    let selectedTag = this.state.tags.find(tag => tag.state === 'selected');

    let contentCreationsBySelectedTags = contentCreations
      .filter(contentCreation => (
        get(contentCreation, 'link', false) ||
        get(contentCreation, 'binary', false) ||
        get(contentCreation, 'imageUrl', false) ||
        get(contentCreation, 'video.url', false) ||
        get(contentCreation, 'video.dash', false) ||
        get(contentCreation, 'video.hls', false)  ||
        get(contentCreation, 'embededContent', null)
      ));

    let audioContentCreationsBySelectedTags = contentCreations
      .filter(contentCreation => contentCreation.audio);

    if (selectedTag) {
      const filterTags = contentCreation => contentCreation.tags.some(tag => tag.id === selectedTag.id);

      contentCreationsBySelectedTags = contentCreationsBySelectedTags.filter(filterTags);
      audioContentCreationsBySelectedTags = audioContentCreationsBySelectedTags.filter(filterTags);
    }

    this.setState({contentCreationsBySelectedTags, audioContentCreationsBySelectedTags});
  }

  handleAddToPlaylistButtonPress = (data, type) => {
    GlobalAddToPlaylistDialog.show({
      type: type,
      contentCreatorType: type === 'contentCreator' && data.type.slug,
      clientId: getActiveClient(),
      selectedTrack: data,
      contentCreatorTagsIds: type === 'contentCreator' && this.getTagsFromContentCreations(this.state.contentCreations).map(tag => tag.id)
    });
  };

  handleFilters = (selectedTags, q = this.state.search, people = this.state.selectedPeople) => {
    selectedTags = selectedTags.filter(selectedTags => selectedTags !=="0")
    let contentCreationsFiltered = this.state.contentCreations.filter(contentCreation => (
      get(contentCreation, 'link', false) ||
      get(contentCreation, 'binary', false) ||
      get(contentCreation, 'imageUrl', false) ||
      get(contentCreation, 'video.url', false) ||
      get(contentCreation, 'video.dash', false) ||
      get(contentCreation, 'video.hls', false)  ||
      get(contentCreation, 'embededContent', null)
    ));

    if (q.length > 0) {
      contentCreationsFiltered = contentCreationsFiltered.filter(cc => cc.title.toLowerCase().includes(q.toLowerCase()));
    }

    if (selectedTags.length > 0) {
      contentCreationsFiltered = contentCreationsFiltered.filter(contentCreation => selectedTags.every(tagId => contentCreation.tags.some(tag => tag.id == tagId)));
    }
  

    if (people) {
      contentCreationsFiltered = contentCreationsFiltered.filter(contentCreation => contentCreation.people.some(person => person.id == people));
    }

    let activeTags = this.getTagsFromContentCreations(contentCreationsFiltered);
    let decoratedTags = this.state.tags.map(t => {
      t._noContent = !activeTags.some(tag => tag.id == t.id);
      t._selected = selectedTags.some(tagId => tagId == t.id);
      return t;
    });
    this.setState({
      search: q,
      contentCreationsBySelectedTags: contentCreationsFiltered,
      tags: decoratedTags,
      selectedTags,
      selectedPeople: people
    });
  }

  renderShareDialog = () => {
    if (!getActiveChannel()) {
      return (
        <ShareDialog
          show={this.state.showShareDialog}
          handleClose={() => this.setState({showShareDialog: false,sharingCreation: null})}
          entity={'contentCreator'}
          id={this.props.contentCreatorId}
        />
      )
    }else {
      return (
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={() => this.setState({showShareDialog: false, sharingCreation: null, shareUrl: null, sharingTitle: false})}
          url={this.state.shareUrl ? this.state.shareUrl :
            `${window.location.protocol}//${window.location.hostname}/${this.state.sharingCreation ? `public/content_creations/${this.state.sharingCreation}` : `public/content_creators/${this.state.contentCreator.hashcode}`}`}
          darkMode={true}
          shareTitle={this.state.sharingTitle || this.state.contentCreator.name}
        />)
    }
  };

  renderContactDialog = () =>
    <Dialog open={this.state.showContactDialog} onBackdropClick={() => this.setState({showContactDialog: false})} maxWidth="false">
      <div style={{width: !checkIfMobile() ? 700 : "75vw"}}>
        <Contact
          modal={true}
          onSubmit={(name, email, description) => request.contentCreator.contact(this.props.contentCreatorId, name, email, description)}
          afterSubmit={() => {
            analyticsInstance.contentAction({
              name: getMainTranslation(this.state.contentCreator, "name"),
              type: "Contact",
            })

            this.setState({showContactDialog: false})
          }}/>
      </div>
    </Dialog>

  renderCheckingPurchasesDialog = () =>
    <Dialog open={this.state.checkingPurchases} disableBackdropClick BackdropProps={{ style: { opacity: "0.7!important" } }} PaperProps={{style: {backgroundColor: "var(--secondary-color, white)"}}}>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "200px"}}>
        <div style={{color: "var(--navbar-text-color, black)", textAlign: "center", fontSize: "1.5em", marginBottom: 20}}>
          Verificando la compra
        </div>
        <img
          style={{ width: 80, height: 80 }}
          src={LoadingSpinner}
          alt="Loading logo"
        />
      </div>
    </Dialog>

  makeBuyButton = (contentCreator) => {
    const { 
      buyButtonProduct, 
      contentCreator: stateCreator,
      openProductDialog 
    } = this.state;
    
    return (
      <BuyButton
        product={buyButtonProduct}
        contentCreator={contentCreator}
        textColor={get(stateCreator, 'preferences.description.color', "#fff")}
        onlySocialButton={get(stateCreator, 'preferences.buy_button.position') === 'social'}
        showBuyButton={!!(
          !isNullOrUndefined(this.props.preview) && 
          get(stateCreator, 'buyButton') && 
          buyButtonProduct &&
          (!get(buyButtonProduct, 'already_bought') || !getStoredUser())
        )}
        preview={this.props.preview}
        isGiftable={get(stateCreator, 'buyButton.isGiftable')}
        onShowLogin={this.props.showLoginDialog}
        onOpenProductDialog={({ productId, returnUrl, isGift }) => {
          this.setState({
            openProductDialog: true,
            productId,
            productReturnUrl: returnUrl,
            isGift
          });
        }}
      />
    );
  }

  makeWorkContainerTitle = (downloadableMaterial) => {
    if (downloadableMaterial && ( downloadableMaterial.audio || downloadableMaterial.video || downloadableMaterial.image || downloadableMaterial.binary || downloadableMaterial.link)) {
      const extentions = downloadableMaterial.type === 'link' ? "" : downloadableMaterial[downloadableMaterial.type === 'other' ? "binary" :downloadableMaterial.type].original_name.split(".").pop();
      const currentType = () => {
        if(downloadableMaterial.audio || downloadableMaterial.video || downloadableMaterial.image || downloadableMaterial.binary) {
          if(downloadableMaterial.audio) {
            return "audio"
          }else if(downloadableMaterial.video) {
            return "video"
          }else if(downloadableMaterial.image) {
            return "image"
          }else {
            return "binary"
          }
        }
        return "link"
      }

      return (
        <div>
          {(downloadableMaterial.audio || downloadableMaterial.video || downloadableMaterial.image || downloadableMaterial.binary) ?
            <>
              <span
                style={{color: this.state.contentCreator.preferences ? this.state.contentCreator.preferences.description.color : "#fff"}}>
                {downloadableMaterial.name}
              </span>
              <IconButton
                style={{
                  marginLeft: 5,
                  color: this.state.contentCreator.preferences ? this.state.contentCreator.preferences.description.color : "#fff"
                }}
                title={`${localization.get('download')} ${!!extentions ? extentions.toUpperCase() : ''}`}
                onClick={() => {
                  analyticsInstance.download({
                    name: getMainTranslation(this.state.contentCreator, "name"),
                    type: "Channel",
                    from: "Material_For_Download",
                  })

                  if (get(downloadableMaterial, "binary.url", null)) {
                    GlobalSnackbar.show({
                      message: localization.get("downloading"),
                      duration: 2000,
                      type: GlobalSnackbarTypes.INFO
                    })

                    return download(get(downloadableMaterial, "binary.url"), downloadableMaterial.original_name)
                  }else {
                    window.open(
                      downloadableMaterial.type === 'image' ? request.contentCreator.downloadMaterial(downloadableMaterial.id)
                        : downloadableMaterial[currentType()].url +
                        `${downloadableMaterial[currentType()].original_name
                          ? `?filename=${encodeURI(downloadableMaterial[currentType()].original_name)}` : ""}`
                      , "_blank")
                  }
                }}
              >
                <CloudDownload/>
              </IconButton>
            </>
            :
            <>
              {downloadableMaterial.link &&
                <Link to={addHttpsToUrl(downloadableMaterial.link)} target="_blank">
                  <span
                    style={{color: this.state.contentCreator.preferences ? this.state.contentCreator.preferences.description.color : "#fff"}}>{downloadableMaterial.name}</span>
                </Link>
              }
            </>
          }
        </div>
      )
    }
  }

  renderEventSchedule = () => {
    let eventDates = this.state.contentCreator.eventDates;
    return <VCS.EventSchedule events={eventDates} additionalStyle={this.state.eventsPosition == 'top' ? {marginBottom: '20px'}: {marginTop: '60px'}}></VCS.EventSchedule>
  }

  scroll = ()  => document.getElementById('reference-scroll-position').scrollIntoView({
    block: "start",
    behavior: "smooth",
  })

  notAvailableDialog = () => {
    return (
      <div>
        {
          GlobalConfirmationDialog.show({
            title: localization.get("content.not_available"),
            content: getStoredChannel() && getStoredChannel().region_blocked_text ?
              (getStoredChannel().region_blocked_text.startsWith('<') ?
                <div dangerouslySetInnerHTML={{__html: getStoredChannel().region_blocked_text}}/> :
                <RichEditor
                  editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(getStoredChannel().region_blocked_text)))}
                  readOnly={true}
                  onChange={() => console.log('read only')}
                />
              )
              : localization.get("content.not_available_for_country"),
            onClose: () => this.props.modal && this.setState({notAvailable: false}),
            hideCancel: true,
            request: () => Promise.resolve().then(!this.props.modal ? browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator('')) : this.props.onModalClose()),
            confirmationLabel: 'content.not_available_done',
          })
        }
      </div>
    )
  }

  renderDrmDialog = () =>
    <Dialog open={this.state.openDrmDialog} onBackdropClick={() => this.setState({openDrmDialog: false})}>
      <DialogContent style={{fontSize: 18}}>
        <p style={{paddingBottom: 5}}><b>{localization.get('no_drm_content.title')}</b></p>
        <p>{getStoredChannel() && getStoredChannel().require_drm_text || localization.get('no_drm_content.text')}</p>
      </DialogContent>
      <DialogActions style={{margin: "0 4% 4%"}}>
        <Button onClick={() => this.setState({openDrmDialog: false, drmDialogOpened: true})} color='default' style={{ backgroundColor: "black" }}>
          {localization.get("button.understood")}
        </Button>
      </DialogActions>
    </Dialog>

  renderCountryBlockedDialog = () =>
    <Dialog open={this.state.openRegionBlockDialog} onBackdropClick={() => this.setState({openRegionBlockDialog: false})}>
      <DialogContent style={{fontSize: 18}}>
        <h4 style={{paddingBottom: 5, marginBottom: 15}}><b>{localization.get('no_content.region_blocked.title')}</b></h4>
        {getStoredChannel() && getStoredChannel().region_blocked_text && getStoredChannel().region_blocked_text != "<p><br></p>"?
          (getStoredChannel().region_blocked_text.startsWith('<') ?
              <div dangerouslySetInnerHTML={{__html: getStoredChannel().region_blocked_text}}/> :
              <RichEditor
                editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(getStoredChannel().region_blocked_text)))}
                readOnly={true}
                onChange={() => console.log('read only')}
              />
          )
          : <p>{localization.get("content.not_available_for_country")}</p>
        }
      </DialogContent>
      <DialogActions style={{margin: "0 4% 4%"}}>
        <Button onClick={() => this.setState({openRegionBlockDialog: false})} color='default' style={{ backgroundColor: "black" }}>
          {localization.get("button.understood")}
        </Button>
      </DialogActions>
    </Dialog>

  downloadCreation = item => {
    if(get(item,'audio.url')) {
      return window.open(item.audio.url, '_blank')
    }
    
    let download = url => window.open(url, '_blank')
    if((/iPhone|iPad|iPod/i.test(navigator.userAgent) || window.safari !== undefined)) {
      // safari doesnt allow window.open inside async calls
      var windowRef=window.open();
      download = url => windowRef.location = addHttpsToUrl(url);
    }

    return request.contentCreation.getDownloadLink(item.id)
      .then(response => {
        const filename =
            item.binary ? item.binary.original_name.split(".").pop() :
            item.video ? item.video.original_name :
            item.image ? item.image.original_name : ".";
        const fileExt =  filename.split('.').pop()



        let url = response.url
        if(url.endsWith(".")) url = `${response.url}${fileExt}`
        downloadFromUrl(url, encodeURI(item.title), fileExt, 'octet/steam')
      })
      .catch(GlobalSnackbar.showGenericError)
  }

  fullDialog = () => {
    return (
      <div>
        {
          GlobalConfirmationDialog.show({
            title: localization.get("content.views_limit_reached"),
            content: getStoredChannel() && getStoredChannel().views_limit || localization.get("content.views_limit_reached.description"),
            hideCancel: true,
            onClose: () => console.log(),
            request: () => new Promise(resolve => resolve())
          })
        }
      </div>
    )
  }

  openProductDialog = (content, isGift=false) =>
    this.setState({
      openProductDialog: true,
      productTargetId: content.id,
      productName: content.name || content.title,
      productImageUrl: content.imageUrl,
      isGift: isGift,
    })

  renderProductDialog = () =>
    <ProductDialog
      open={this.state.openProductDialog}
      productTargetId={this.state.productTargetId}
      productTargetType={this.state.productId ? null : this.state.contentCreator.paymentRequired ? "contentCreator" : "contentCreation"}
      name={this.state.productName}
      imageUrl={this.state.productImageUrl}
      productId={this.state.productId}
      returnUrl={this.state.productReturnUrl}
      onClose={() => this.setState({
        openProductDialog: false,
        productTargetId: null,
        productName: "",
        productImageUrl: "",
        productId: null,
        productReturnUrl: null,
        isGift: false,
      })}
      isGift={this.state.isGift}
    />

  shareContent = (item = null) => {
    if (getActiveChannel() && getStoredChannel() &&  getStoredChannel().custom_share) {
      const text = window.location.href;
      const fallbackCopyTextToClipboard = (text) =>  {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
      };

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      analyticsInstance.share({
        name: getMainTranslation(this.state.contentCreator, "name"),
        type: "Content",
      })

      navigator.clipboard.writeText(text).then(function() {
        GlobalSnackbar.show({
          message: localization.get('copy.success'),
          type: GlobalSnackbarTypes.SUCCESS
        })
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }else {
      if (getUseTools()) {
        const requestType = item ? "contentCreation" : 'contentCreator';
        return request[requestType].shareByChannel(item ? item.id : this.state.contentCreator.id)
          .then(rest => {
            analyticsInstance.share({
              name: item ? getMainTranslation(item, "title") : getMainTranslation(this.state.contentCreator, "name"),
              type: item ? "Material": "Content",
            })
            this.setState({showShareDialog: true, shareUrl: rest.url})
          })
          .catch(GlobalSnackbar.showGenericError)
      } else {
        analyticsInstance.share({
          name: item ? getMainTranslation(item, "title") : getMainTranslation(this.state.contentCreator, "name"),
          type: item ? "Material": "Content",
        })
        const isPublic = getStoredChannel() && getStoredChannel().visibility == 'public';
        return item ? this.setState({showShareDialog: true, sharingCreation: item.hashcode, sharingTitle: item.title})
          : isPublic ? this.setState({
            showShareDialog: true,
            shareUrl: `${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`,
            sharingTitle: this.state.contentCreator.name,
          }) : this.setState({showShareDialog: true})
      }
    }
  }

  onBuyProduct = (isGift=false) =>
    !getStoredUser() ?
      this.props.showLoginDialog() :
      (
        (!!this.state.buyButtonProduct && !!this.state.contentCreator.buyButton && !!this.state.contentCreator.buyButton.productId) ?
          this.setState({openProductDialog: true, productId: this.state.contentCreator.buyButton.productId, isGift: isGift}):
          this.openProductDialog(this.state.contentCreator, isGift)
      )

  storeInappropriate = () => localStorage.setItem("over_18", true);

  render = () => {
    let {contentCreator, editingContentCreation, favorite, tags, audioContentCreationsBySelectedTags, contentCreationsBySelectedTags, height, viewType} = this.state;

    if(this.state.requireLogin && !getStoredUser() && !!this.storedChannel){
      return this.props.showLoginDialog();
    }

    if (this.state.notAvailable) {
      return this.notAvailableDialog();
    }

    if(this.state.fullContent) {
      return this.fullDialog();
    }

    contentCreationsBySelectedTags = (contentCreator.type.limit != null) ? contentCreationsBySelectedTags.slice(0, contentCreator.type.limit) : contentCreationsBySelectedTags

    const item = contentCreationsBySelectedTags[this.state.currentIndex];
    const key = item && contentCreationsBySelectedTags.findIndex(contentCreation => contentCreation.id === item.id);

    const isMobile = window.innerWidth < 600;
    const hasBackgroundColor = contentCreator.preferences && contentCreator.preferences.background_color && contentCreator.preferences.background_color != "none";
    const backgroundGradient = contentCreator.preferences && contentCreator.preferences.background_gradient;

    const showBuyButton = !!(isNullOrUndefined(this.props.preview) && (
        (this.state.contentCreator.buyButton && this.state.buyButtonProduct && (!this.state.buyButtonProduct.already_bought || !getStoredUser())) ||
        (!this.state.contentCreator.buyButton && this.state.contentCreator.paymentRequired)
      ) && this.state.contentCreator.preferences && this.state.contentCreator.preferences.buy_button &&
      this.state.contentCreator.preferences.buy_button.position !== 'description')

    const showGiftButton = !!(isNullOrUndefined(this.props.preview) && !!getStoredUser() && !!get(this.state.contentCreator, "buyButton") && !!get(this.state.buyButtonProduct, "is_giftable"));
    const socialPosition = get(getStoredChannel(), 'preferences.content_creator.social_position', 'default');
    const additionInfoFontSize = contentCreator.additionalInfoSize == "sm" ? 20 : contentCreator.additionalInfoSize == "md" ? 30 : 45 

    return (
      <React.Fragment>
        {userCanEditCreators() &&
        <>
          <EditContentCreatorDialog 
            open={this.state.editContentCreatorDialogOpen}
            contentCreator={contentCreator}
            onClose={() => this.setState({editContentCreatorDialogOpen: false})}
          />
          <EditContentCreationDialog 
            open={this.state.editContentCreationDialogOpen}
            contentCreation={editingContentCreation}
            type={get(contentCreator, 'type.slug')}
            onClose={() => this.setState({editContentCreationDialogOpen: false})}
          />
        </>
        }
        {getActiveChannel() &&
          <Helmet>
            <link
              rel="canonical"
              href={(!!contentCreator.canonicalChannel) ?
                `${addHttpsToUrl(contentCreator.canonicalChannel)}${channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`)}` :
                `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`)}`
              }
            />
          </Helmet>
        }
        <VCS.ViewContainer
          bgImage={!hasBackgroundColor ? contentCreator.coverImageUrl : null}
          bgColor={hasBackgroundColor ? contentCreator.preferences.background_color : null}
          gradient={(hasBackgroundColor && backgroundGradient != null) ? backgroundGradient && backgroundGradient != 0 : true}>
          <VCS.TopContainer alternative={socialPosition=="alternative"}>
            {this.props.modal &&
              <div style={
                window.innerWidth > 600 ?
                  {
                    left: (catalogClientView() && this.state.width > 1200) && "66px",
                    position: "absolute",
                    float: "left",
                    width: "auto",
                    cursor: "pointer",
                    top: 0,
                    zIndex: 1,
                  }
                  :
                  {
                    position: "absolute",
                    cursor: "pointer",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }
              }>
                <div className="navbar-image-container-link" style={{top: window.innerWidth > 600 ? '18px' : '14px', maxWidth: window.innerWidth < 769 ? "180px" : "auto", width: "auto"}} onClick={() => this.props.onModalClose()}>
                  {getActiveChannel() ?
                    <img style={{ position: "relative",float: "left", width: "100%",filter: " brightness(0) invert(1)", maxWidth: 250, maxHeight: 40}}
                         src={(this.storedChannel && this.storedChannel.image) ?
                           makeFitImageUrl(this.storedChannel, 250, 40, '', 'png')
                           : (this.storedChannel ?
                             makeFitImageUrl(this.storedChannel.client, 250, 40, '', 'png')
                             : Logo)}
                         alt={`Logo - ${getActiveChannel() ? this.storedChannel.name : "Feater"}`}/>
                    :
                    <img
                      className="navbar-image" style={{ maxWidth: "130px"}}
                      src={(this.state.client && this.state.client.use_logo && this.state.client.image) ?
                        makeFitImageUrlWithSize(this.state.client, 'sm', 'png') : Logo}
                      alt={`Logo ${getActiveChannel() ? this.storedChannel.name : "Feater"}`}/>
                  }
                </div>
              </div>
            }

            {socialPosition=="alternative" &&
             <div style={{display: "flex", position: "relative", flexDirection: "column", marginTop: isMobile ? 50 : 75}}>
                <div onClick={() => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose()}
                 style={{display: "flex", position: "relative", flexDirection: "row", alignItems: "center", marginBottom: 15, cursor: "pointer"}}>
                  <ChevronLeft style={{color: contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"}}/>
                  <span style={{color: contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"}}>{localization.get('go_back')}</span>
                </div>
                <div style={{display: "flex", position: "relative", flexDirection: "row", alignItems: "center"}}>
                  <Profile picture={contentCreator.imageUrl} blur={false} />
                  {((!!getActiveClient() && !!getStoredUser() && !getActiveChannel()) || (getActiveChannel() && channelCanAddToFavorites())) &&
                    <Tooltip placement="bottom" title={localization.get("button.add_to_favorites")}>
                      <SocialButton>
                        <IconButton onClick={this.toggleFavorite} style={isMobile ? {height: 33, width: 33} : {height: 40, width: 40}}>
                          {favorite ? <Star style={{fontSize: "25px", color: "white"}}/> :
                            <StarBorder style={{fontSize: "25px", color: "white"}}/>}
                        </IconButton>
                      </SocialButton>
                    </Tooltip>
                  }

                  {(!!getActiveClient() && (!getActiveChannel() || getUseTools()) && !!getStoredUser()) &&
                      <AddToPlaylist
                        content='contentCreators'
                        contentCreatorType={contentCreator.type.slug}
                        clientId={getActiveClient()}
                        item={contentCreator}
                        extraStyle={window.innerWidth < 600 ? {height: 33, width: 33, display: 'flex', alignItems: 'center', justifyContent: 'center'} : {height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        icon={<SocialButton><PlaylistAdd style={{fontSize: "25px", color: "white", margin: "4px 6px"}}/></SocialButton>}
                        tooltipTitle={localization.get("add_to_playlist")}
                      />
                  }
                  
                  <Tooltip placement="bottom" title={localization.get("share")}>
                    <SocialButton>  
                      <IconButton onClick={() => this.shareContent()} style={isMobile ? {height: 33, width: 33} : {height: 40, width: 40}}>
                        <Share style={{fontSize: "25px", color: "white"}}/>
                      </IconButton>
                    </SocialButton>
                  </Tooltip>
              
                </div>
              </div>
            }

            <VCS.TopContainerInfoContainer className={style['audiovisual-info-container']} InfoContainer alternative={socialPosition=="alternative"}>
              {contentCreator.additions && contentCreator.additions.split(';')[1] &&
                <VCS.TopContainerCountry color={contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"} >
                  {contentCreator.additions.split(';')[1]}
                </VCS.TopContainerCountry>
              }

              <VCS.TopContainerName
                style={{overflow: 'hidden', textOverflow: 'ellipsis', color: contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"}}
                Name>
                  {contentCreator.name}
                  {!!userCanEditCreators() &&
                    <Tooltip title={localization.get('edit')} onClick={() => this.setState({editContentCreatorDialogOpen: true})} placement="top">
                      <Edit style={{margin: "0 10px"}}  onClick={() => this.setState({editContentCreatorDialogOpen: true})} />
                    </Tooltip>
                  }
                </VCS.TopContainerName>

              {!isMobile && this.state.eventsPosition == 'top' && contentCreator.isEvent && this.renderEventSchedule()}

              {contentCreator.country &&
                <VCS.TopContainerCountry color={contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"} >{`${localization.get('nationality')}: ${contentCreator.country.name}`}</VCS.TopContainerCountry>}

              {contentCreator.description &&
                <VCS.TopContainerDescription
                  text={contentCreator.description}
                  height={height}
                  color={contentCreator.preferences ? contentCreator.preferences.description.color : "#fff"}
                  onWidthChange={() => this.setState(prevState => ({...prevState, descriptionMaxWidth: prevState.descriptionMaxWidth === '58%' ? '80%' : '58%'}))}
                  useShowMore={get(contentCreator, 'preferences.show_more_button', "use") === "use"}
                />
              }

              {!isMobile && this.state.eventsPosition == 'bottom' && contentCreator.isEvent && this.renderEventSchedule()}
            </VCS.TopContainerInfoContainer>
            {socialPosition == "default" &&
             <VCS.SocialBanner
              socialLinks={contentCreator.socialLinks}
              onClickSocial={() => analyticsInstance.contentAction({
                name: getMainTranslation(this.state.contentCreator, "name"),
                action: "Visit_Social_Network",
              })}
              name={contentCreator.type.is_talent ? contentCreator.name : ""}
              picture={contentCreator.imageUrl}
              blur={contentCreator.type === "voice_over"}
              showFavoriteButton={(!!getActiveClient() && !!getStoredUser() && !getActiveChannel()) || (getActiveChannel() && channelCanAddToFavorites())}
              showShareButton={true}
              showAddToPlaylistButton={!!getActiveClient() && (!getActiveChannel() || getUseTools()) && !!getStoredUser()}
              favorite={favorite}
              onToggleFavorite={this.toggleFavorite}
              onAddToPlaylist={() => this.handleAddToPlaylistButtonPress(contentCreator, 'contentCreator')}
              playlistButton={
                <AddToPlaylist
                  content='contentCreators'
                  contentCreatorType={contentCreator.type.slug}
                  clientId={getActiveClient()}
                  item={contentCreator}
                  extraStyle={window.innerWidth < 600 ? {height: 33, width: 33, display: 'flex', alignItems: 'center', justifyContent: 'center'} : {height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                  icon={<PlaylistAdd style={{fontSize: "25px", color: "white", marginLeft: 4}}/>}
                  tooltipTitle={localization.get("add_to_playlist")}
                />
              }
              onClose={() => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose()}
              onShare={()=> this.shareContent()}
              showContact={this.state.contentCreator.canContact}
              onContact={() => this.setState({showContactDialog: true})}
              showBuyButton={showBuyButton && this.state.contentCreator.preferences && this.state.contentCreator.preferences.buy_button && this.state.contentCreator.preferences.buy_button.position !== 'description'}
              showGiftButton={showGiftButton && get(this.state.contentCreator, "preferences.buy_button.position") !== 'description'}
              onBuyProduct={() => this.onBuyProduct()}
              onGiftProduct={() => this.onBuyProduct(true)}
              buyButtonPreferences={this.state.contentCreator.preferences && this.state.contentCreator.preferences.buy_button}
              color={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}
              shape={contentCreator.preferences ? contentCreator.preferences.card.shape : "rounded"}
              backgroundColor={!!contentCreator.preferences ? contentCreator.preferences.card.color : "linear-gradient(to bottom, #918e8e40, darkgray)"}
              isModal={this.props.modal}
            />}
          </VCS.TopContainer>
          {isMobile && contentCreator.isEvent &&
            <VCS.TopContainer>
              <VCS.TopContainerInfoContainer style={{maxWidth: "100%", marginTop: 30}}>
                {this.renderEventSchedule()}
              </VCS.TopContainerInfoContainer>
            </VCS.TopContainer>
          }

        {socialPosition == "alternative" && contentCreator.socialLinks.length > 0 &&
          <div style={{
            marginTop: 20,
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: contentCreator.preferences ? contentCreator.preferences.title.color : "#fff",
            filter: "invert(1)"
          }}>
          <SocialButtons color={contentCreator.preferences ? contentCreator.preferences.title.color : "#fff"}>
            {renderSocialLinksButtons(contentCreator.socialLinks, false, () => analyticsInstance.contentAction({
                name: getMainTranslation(this.state.contentCreator, "name"),
                action: "Visit_Social_Network",
              }))}
          </SocialButtons>
          </div>
        }

          <React.Fragment>
            <h3 className={style["audiovisual-description-h3"]} style={{color: contentCreator.preferences ? contentCreator.preferences.description.color : "#fff", fontSize: additionInfoFontSize }}>{this.makeWorkContainerTitle(contentCreator.downloadableMaterial)}</h3>
          </React.Fragment>
          
          {(this.state.buyButtonProduct || !getStoredUser() || showBuyButton) &&
            <React.Fragment>
              {isNullOrUndefined(this.props.preview) &&
                <h3 className={contentCreator.downloadableMaterial ? style["audiovisual-description-h3-no-margin"] : style["audiovisual-description-h3"]}  style={{fontSize: additionInfoFontSize, color: contentCreator.preferences ? contentCreator.preferences.description.color : "#fff"}}>{this.makeBuyButton(contentCreator)}</h3>
              }
            </React.Fragment>
          }
          {(!this.state.creationsLoaded) &&
            <div style={{ marginTop: "20vh", display: "flex", justifyContent: "center", alignContent: "center", position: 'relative' }}>
              <img style={{width: 100, height: 100}} src={LoadingSpinner} alt="Loading logo"/>
            </div>
          }
          {!(this.state.requireLogin && !getStoredUser()) && (this.state.contentCreations.length > 0 || viewType !== 'grid') &&
            <div style={{display: (this.state.creationsLoaded && this.state.contentCreations.length > 0) ? 'block' : 'none'}}>
              <VCS.WorkContainer WorkContainer color={contentCreator.preferences ? contentCreator.preferences.card.color : "rgba(255, 255, 255, 0.4)"} shape={contentCreator.preferences ? contentCreator.preferences.card.shape : "rounded"}>
                {(contentCreator.type.allow_audio && audioContentCreationsBySelectedTags.length > 0) && viewType == 'carousel' &&
                  <>
                    {tags && tags.length > 0 &&
                      <div style={{marginTop: 30, marginBottom: 30, textAlign: "center"}}>
                        <HomeItemDescriptionTags outlined tags={tags} onSelectedTagChanged={this.onSelectedTagChanged} color={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"} />
                      </div>
                    }
                    <TrackResult
                      rows={
                        <TrackTable
                          {...this.props}
                          hideImage={true}
                          type={'contentCreation'}
                          hideSuggestions={true}
                          tracks={ (contentCreator.type.limit == null) ?
                            audioContentCreationsBySelectedTags.filter(contentCreation => contentCreation.audio) :
                            audioContentCreationsBySelectedTags.slice(0, contentCreator.type.limit).filter(contentCreation => contentCreation.audio) }
                          trackContainer={{
                            ...contentCreator,
                            title: contentCreator.name,
                          }}
                          transparentBackground
                          color={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}
                          onShare={(item)=> this.shareContent(item)}
                        />
                      }
                    />
                  </>
                }
                {(contentCreator.type.allow_video || contentCreator.type.allow_image || contentCreator.type.allow_link ||
                    contentCreator.type.allow_binary || contentCreator.type.allow_embed) && this.state.contentCreations.length > 0 &&
                  <div ref={this.myRef}>
                    {window.innerWidth > 700 && this.state.contentCreations.filter(contentCreation => !contentCreation.audio).length > 1 && viewType == 'carousel' &&
                      <VCS.WorkContainerCarousel
                        tags={tags}
                        onSelectedTagChanged={this.onSelectedTagChanged}
                        showAddToPlaylist={this.props.showAddToPlaylist}
                        items={contentCreationsBySelectedTags}
                        showEmpty={this.state.contentCreations.filter(contentCreation => !contentCreation.audio).length > 1}
                        handleFilters={this.handleFilters}
                        color={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}
                        showSearchInput={this.state.contentCreations.filter(contentCreation => (
                          get(contentCreation, 'link', false) ||
                          get(contentCreation, 'binary', false) ||
                          get(contentCreation, 'imageUrl', false) ||
                          get(contentCreation, 'video.url', false) ||
                          get(contentCreation, 'video.dash', false) ||
                          get(contentCreation, 'video.hls', false)  ||
                          get(contentCreation, 'embededContent', null)
                        )).length > 6}
                        people={this.props.people}
                        renderItem={contentCreation => {
                          let content = contentCreation.smallImageUrl ?
                            <img
                             alt="contentCreation"
                              style={{
                                objectFit: "cover",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                padding: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color && contentCreator.preferences.creation.carousel_background_color == 'transparent') ? 0 : 5,
                                backgroundColor: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color) ? contentCreator.preferences.creation.carousel_background_color : "#ababab"
                              }} 
                              src={contentCreation.smallImageUrl}
                              onError={onImageError}
                            />
                            : contentCreation.smallUrl &&
                            <video
                              style={{
                                objectFit: "cover",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                padding: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color && contentCreator.preferences.creation.carousel_background_color == 'transparent') ? 0 : 5,
                                backgroundColor: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color) ? contentCreator.preferences.creation.carousel_background_color : "#ababab"
                              }}
                              src={contentCreation.smallUrl}
                              preload="none"
                              poster={contentCreation.imageUrl || contentCreation.thumbnail}
                            />
                          if (contentCreation.link) {
                            content = <Tooltip title={localization.get("visit")} followCursor={true}>{content}</Tooltip>
                          }
                          return content;
                        }}
                        onItemClick={contentCreation => {
                          if (contentCreation.link) {
                            window.open(addHttpsToUrl(contentCreation.link, '_blank'));
                          } else {
                            this.setState({autoPlay: true}, () => {
                              this.onChangeMaterial(contentCreation.itemIndex);
                              this.scroll();
                            });
                          }
                        }}
                      />
                    }
                    {item && viewType == 'carousel' &&
                      <CreationItemContainer>
                        {contentCreator.preferences && contentCreator.preferences.creation.inverted &&
                          <>
                            <h2 style={{
                              fontWeight: "bold",
                              color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000",
                              fontSize: window.innerWidth < 650 ? 18 : 30,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              height: "100%",
                              position: "relative",
                              marginTop: 0,
                            }}>
                              {item.title}
                            </h2>
                            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                              {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                                <AddToPlaylist
                                  content='contentCreations'
                                  clientId={getActiveClient()}
                                  item={item}
                                  extraStyle={{height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-3px', cursor: 'pointer'}}
                                  icon={<PlaylistAdd style={{fontSize: window.innerWidth > 600 ? 30 :20 , color:contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}}/>}
                                  tooltipTitle={localization.get('ranking_config.playlist_add')}
                                />
                              }
                              {item.allowDownload &&
                                <IconButton onClick={() => this.downloadCreation(item)}
                                            style={{right: -15, top: -3, width: 35}}>
                                  <Tooltip title={localization.get('download.original')}>
                                    <CloudDownload style={{
                                      fontSize: window.innerWidth > 600 ? 30 : 20,
                                      color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                    }}/>
                                  </Tooltip>
                                </IconButton>
                              }
                              {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                                <IconButton
                                  onClick={() => this.shareContent(item)}
                                  style={{right: -15, top: -3, width: 35}}>
                                  <Tooltip title={localization.get('share')}>
                                    <Share style={{
                                      fontSize: window.innerWidth > 600 ? 30 : 20,
                                      color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                    }}/>
                                  </Tooltip>
                                </IconButton>
                              }
                              {!!userCanEditCreators() &&
                                <Tooltip title={localization.get('edit')} placement="top">
                                  <Edit style={{margin: "0 10px"}}  onClick={() => this.setState({editContentCreationDialogOpen: true, editingContentCreation: item})} />
                                </Tooltip>
                              }
                            </div>
                          </>
                        }
                        <div id="reference-scroll-position"
                             style={{position: "absolute", top: this.props.modal ? "-270px" : "-340px"}}/>
                        {item && (
                          <div style={{display: key !== this.state.currentIndex && 'none'}} key={key}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              {this.state.contentCreations.filter(contentCreation => !contentCreation.audio).length > 1 &&
                                <IconButton disabled={key < 1}
                                            onClick={() => this.onChangeMaterial(key - 1)}
                                            style={window.innerWidth < 600 ? {height: 20, width: 20} : ((window.innerWidth > 600 && window.innerWidth < 800) ? {height: 40, width: 40, position: "absolute", left: "-30px"} : {height: 40, width: 40})}>
                                  <ChevronLeft style={{
                                    fontSize: 40,
                                    color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                  }}/>
                                </IconButton>
                              }
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexGrow: 4,
                                  position: 'relative'
                                }}
                              >
                                {(item.inappropriate_content && this.state.showInappropriate) &&
                                  <div className="content-creation-overlay-container">
                                    {!this.state.hideInappropriate &&
                                      <div className="content-creation-inappropriate">
                                        <p>{getStoredChannel() && getStoredChannel().restricted_content_text || localization.get("creation.inappropriate_content.warning_text")}</p>
                                        <div className="content-creation-inappropriate-buttons">
                                          <Button className="content-creation-inappropriate-button" onClick={() => this.setState({showInappropriate: false})} color='default' style={{ backgroundColor: "var(--main-font-color, black)", color: "var(--main-color, white)" }}>
                                            {localization.get("creation.inappropriate_content.of_age")}
                                          </Button>
                                          <Button className="content-creation-inappropriate-button" onClick={() => this.setState({hideInappropriate: true})} color='default' style={{ backgroundColor: "var(--main-font-color, black)", color: "var(--main-color, white)" }}>
                                            {localization.get("creation.inappropriate_content.not_of_age")}
                                          </Button>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }
                                
                                {((item.requireLogin && item.imageUrl && !getStoredUser()) ||
                                  (item.imageUrl && isNullOrUndefined(this.props.preview) && item.viewLimit > 0 && item.viewLimit <= item.views && !item.video) ||
                                  item.blockedForCountry || item.paymentRequired || item.requireAccessLevel) ?
                                  <div className={'content-creation-image-container'}>
                                    <img
                                      alt="photo"
                                      className={'content-creation-image'}
                                      style={{
                                        maxWidth: window.innerWidth > 700 ? "90%" : "100%",
                                        cursor: "pointer",
                                        border: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color) ? (contentCreator.preferences.creation.carousel_background_color == 'transparent' ? 'none' : `5px solid ${contentCreator.preferences.creation.carousel_background_color}`) : '5px solid #ababab',
                                      }}
                                      onClick={() => {
                                        if (item.blockedForCountry) {
                                          !this.state.openRegionBlockDialog && this.setState({openRegionBlockDialog: true})
                                        } else if (isNullOrUndefined(this.props.preview) && item.viewLimit > 0 && item.viewLimit <= item.views) {
                                          this.fullDialog()
                                        } else if (item.requireLogin && !getStoredUser()) {
                                          this.props.showLoginDialog()
                                        } else if (item.paymentRequired) {
                                          this.setState({paymentDialog: true})
                                        }else if (item.requireAccessLevel) {
                                          GlobalConfirmationDialog.show({
                                            title: localization.get("content.access_denied"),
                                            content: localization.get("content.not_enough_permissions"),
                                            onClose: () => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose(),
                                            request: () => Promise.resolve().then(!this.props.modal ? browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator('')) : this.props.onModalClose())
                                          })
                                        }
                                      }}
                                      src={item.imageUrl}
                                      onError={onImageError}
                                    />
                                    
                                    <div
                                      className={"content-creation-image-over-play"}
                                      style={{
                                        maxWidth: window.innerWidth > 700 ? "90%" : "100%", cursor: "pointer"
                                      }}
                                      onClick={() => {
                                        if (item.blockedForCountry) {
                                          !this.state.openRegionBlockDialog && this.setState({openRegionBlockDialog: true})
                                        } else if (isNullOrUndefined(this.props.preview) && item.viewLimit > 0 && item.viewLimit <= item.views) {
                                          this.fullDialog()
                                        } else if (item.requireLogin && !getStoredUser()) {
                                          this.props.showLoginDialog()
                                        } else if (item.paymentRequired) {
                                          if (!getStoredUser()) {
                                            this.props.showLoginDialog()
                                          } else {
                                            this.openProductDialog(this.state.contentCreator.paymentRequired ? this.state.contentCreator : item)
                                          }
                                        }else if (item.requireAccessLevel) {
                                          GlobalConfirmationDialog.show({
                                            title: localization.get("content.access_denied"),
                                            content: localization.get("content.not_enough_permissions"),
                                            onClose: () => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose(),
                                            request: () => Promise.resolve().then(!this.props.modal ? browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator('')) : this.props.onModalClose())
                                          })
                                        }
                                      }}
                                    >
                                      {item.paymentRequired ?
                                        <div className={"content-creation-image-over-play-text"}
                                             style={{display: 'flex', flexDirection: 'column'}}>
                                          <p style={{textAlign: "center"}}>
                                            <ShoppingCartOutlinedIcon style={{fontSize: 60, color: "white", cursor: "pointer"}}/>
                                          </p>
                                          <p style={{
                                            textAlign: "center",
                                            fontSize: 18
                                          }}>{localization.get('product.is_monetized')}</p>
                                        </div>
                                        :
                                        <PlayArrow
                                          style={{
                                            color: "white",
                                            background: "#ccc",
                                            border: "1px solid #ccc",
                                            fontSize: 50,
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            left: "calc( 50% - 25px )",
                                            maxWidth: 50,
                                          }}
                                          className={"content-creation-image-over-play-text"}
                                        />
                                      }
                                    </div>
                                  </div>
                                  :
                                  <>
                                    {item.video ?
                                      <ShakaPlayer
                                        material={item}
                                        blur={this.state.showInappropriate && item.inappropriate_content}
                                        showThumbnail
                                        preRollVideo={item.has_pre_roll && this.storedChannel && this.state.showPreRoll && this.storedChannel.preRollVideo}
                                        video={item.video}
                                        autoPlay={this.state.autoPlay}
                                        thumbnail={item.thumbnail}
                                        subtitleFontSize={contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.subtitles ? contentCreator.preferences.creation.subtitles.font_size : null}
                                        subtitleMargin={contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.subtitles ? contentCreator.preferences.creation.subtitles.margin : null}
                                        videoTitle={item.title}
                                        timeToTrack={item.timeToTrack}
                                        preview={this.props.preview}
                                        mainMaterialTracked={this.state.playedCreations && this.state.playedCreations.includes(item.id)}
                                        contentCreationId={item.id}
                                        limited={item.limited}
                                        trackingToken={item.trackingToken}
                                        origen={this.props.origen}
                                        trackedMainMaterialPlayed={() => {
                                          if (!this.state.playedCreations.includes(item.id)) {
                                            this.state.playedCreations.push(item.id)
                                            this.setState({playedCreations: this.state.playedCreations})
                                          }
                                        }}
                                        setAutoPlay={(value) => this.setState({autoPlay: value})}
                                        setShowPreRoll={(value) => this.setState({showPreRoll: value})}
                                        reloadDrm={async () => {
                                          await this.props.reloadContentCreator();
                                          const updatedContentCreation = this.state.contentCreations.find(contentCreation => contentCreation.id === item.id);
                                          return get(updatedContentCreation, 'video.drm');
                                        }}
                                        onError={() => this.setState({openDrmDialog: true})}
                                      />
                                      : item.embededContent ?
                                        <EmbededContent
                                          material={item}
                                          content={item.embededContent}
                                          index={key}
                                          blur={this.state.showInappropriate && item.inappropriate_content}
                                          currentIndex={this.state.currentIndex} trackingTitle={item.title}
                                          />
                                        : item.imageUrl &&
                                        <div className={(this.state.showInappropriate && item.inappropriate_content) ? 'content-creation-image-container blurred-container' : 'content-creation-image-container'}>
                                          <img
                                            alt="photo"
                                            className={'content-creation-image'}
                                            style={{
                                              maxWidth: window.innerWidth > 700 ? "90%" : "100%",
                                              border: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color) ? (contentCreator.preferences.creation.carousel_background_color == 'transparent' ? 'none' : `5px solid ${contentCreator.preferences.creation.carousel_background_color}`) : '5px solid #ababab',
                                            }}
                                            src={item.imageUrl}
                                            onError={onImageError}
                                          />
                                          {(item.binary || item.link || (item.image && item.image.original_name && item.image.original_name.endsWith('.gif'))) &&
                                            <div className={"content-creation-image-over"}>
                                              <p style={{
                                                textAlign: "center",
                                                color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                              }}>
                                    <span
                                      onClick={() => {
                                        if(this.state.downloading) return;

                                        if (item.image && item.image.original_name && item.image.original_name.endsWith('.gif') && !item.binary) {
                                          downloadFromUrl(item.image.url, encodeURI(item.image.original_name), 'gif', 'image/gif')
                                        } else {
                                          if (item.binary && item.binaryExtension == "pdf") {
                                            window.open(item.binary, '_blank');
                                          }else {
                                            !item.link ? this.downloadCreation(item) : window.open(addHttpsToUrl(item.link, '_blank'));
                                          }    
                                          analyticsInstance.download({
                                            name: getMainTranslation(this.state.contentCreator, "name"),
                                            type: "Channel",
                                            from: "Material_For_Download",
                                          })
                                          // GAevent('Contenido', "Descargar", item.title)
                                        }
                                      }}
                                      className={"visit_button"}
                                      style={{color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}}
                                    >
                                      {item.link ? localization.get('visit') : item.binaryExtension == "pdf" ? localization.get('open_pdf') : `${localization.get(this.state.downloading ? 'downloading': 'download')} ${item.binaryExtension.toUpperCase() || (item.image && item.image.original_name && item.image.original_name.endsWith('.gif') ? "GIF" : "")}`}
                                    </span>
                                              </p>
                                            </div>
                                          }
                                        </div>
                                    }
                                  </>
                                }
                              </div>

                              {this.state.contentCreations.filter(contentCreation => !contentCreation.audio).length > 1 &&
                                <IconButton onClick={() => this.onChangeMaterial(key + 1)} disabled={key >= contentCreationsBySelectedTags.length - 1}
                                 style={window.innerWidth < 600 ? {height: 20, width: 20} : ((window.innerWidth > 600 && window.innerWidth < 800) ? {height: 40, width: 40, position: "absolute", right: "-30px"} : {height: 40, width: 40})}>
                                  <ChevronRight style={{
                                    fontSize: 40,
                                    color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                  }}/>
                                </IconButton>
                              }
                            </div>
                            {(!contentCreator.preferences || !contentCreator.preferences.creation.inverted) &&
                              <>
                                <h2 style={{
                                  fontWeight: "bold",
                                  color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000",
                                  fontSize: window.innerWidth < 650 ? 18 : 30,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  height: "100%",
                                  position: "relative"
                                }}>
                                  {item.title}
                                </h2>
                                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                                  {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                                    <AddToPlaylist
                                      content='contentCreations'
                                      clientId={getActiveClient()}
                                      item={item}
                                      extraStyle={{height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-3px', cursor: 'pointer'}}
                                      icon={<PlaylistAdd style={{fontSize: window.innerWidth > 600 ? 30 :20 , color:contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}}/>}
                                      tooltipTitle={localization.get('ranking_config.playlist_add')}
                                    />
                                  }
                                  {item.allowDownload &&
                                    <IconButton onClick={() => this.downloadCreation(item)}
                                                style={{right: -15, top: -3, width: 35}}>
                                      <Tooltip title={localization.get('download.original')}>
                                        <CloudDownload style={{
                                          fontSize: window.innerWidth > 600 ? 30 : 20,
                                          color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                        }}/>
                                      </Tooltip>
                                    </IconButton>
                                  }
                                  {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                                    <IconButton
                                      onClick={() => this.shareContent(item)}
                                      style={{right: -15, top: -3, width: 35}}>
                                      <Tooltip title={localization.get('share')}>
                                        <Share style={{
                                          fontSize: window.innerWidth > 600 ? 30 : 20,
                                          color: contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"
                                        }}/>
                                      </Tooltip>
                                    </IconButton>
                                  }
                                  {!!userCanEditCreators() &&
                                    <Tooltip title={localization.get('edit')} placement="top">
                                      <Edit style={{margin: "0 10px"}}  onClick={() => this.setState({editContentCreationDialogOpen: true, editingContentCreation: item})} />
                                    </Tooltip>
                                  }
                                </div>
                              </>
                            }
                            {item.description &&
                              <VCS.TopContainerBottomDescription
                                text={item.description}
                                height={"100%"}
                                color={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}
                              />
                            }
                          </div>
                        )}
                      </CreationItemContainer>
                    }
                  </div>
                }
                {viewType == 'grid' &&
                  <div>
                    <div id="reference-scroll-position" style={{position: "absolute", top: this.props.modal ? "-270px" : "-340px"}}/>
                    <SmartSearchContentCreatorCreations
                      channel={getStoredChannel()}
                      type={this.state.contentCreator.type}
                      contentCreatorId={this.state.contentCreator.id}
                      onReady={() => this.onCreationsLoaded()}
                      onScrollToTop={() => this.scroll()}
                      creationColor={contentCreator.preferences ? contentCreator.preferences.creation.color : "#000"}
                      modal={this.props.modal}
                    />
                  </div>
                }
                {contentCreator.showTags && get(contentCreator.tags, 'length', 0) > 0 &&
                  <div id={'tags-container'} style={{marginBottom: 15, }}>
                    <h4>{localization.get('tags')}</h4>
                    <div style={{marginBottom: 30}}>
                      {contentCreator.tags.filter(tag => tag.show_on_smart_search).sort((a, b) => a.name.localeCompare(b.name)).map((tag, j) => {
                        return (
                          <div className={`smart-search-tag-containter horizontal`}
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  display: "inline-block",
                                  marginRight: 10
                                }}>
                            <Chip
                              key={j}
                              className={'editorial-chip'}
                              label={<span
                                className={`${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{tag.name}</span>}
                              onClick={() => {
                                this.props.modal && this.props.onModalClose()
                                if (getActiveChannel()) {
                                  browserHistory.push(channelPathGenerator(`busqueda-inteligente/contenido/${contentCreator.type.id}-${slugify(contentCreator.type.name)}?type=${contentCreator.type.slug}&tags_ids=${tag.id}&fromEditorial=true`))
                                } else {
                                  browserHistory.push(homePathGenerator(`busqueda-inteligente/contenido/${contentCreator.type.id}-${slugify(contentCreator.type.name)}?type=${contentCreator.type.slug}&tags_ids=${tag.id}`))
                                }
                                this.props.onTagChange && this.props.onTagChange(tag.id)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                }
              </VCS.WorkContainer>
            </div>
          }
          {contentCreator.hasSuggested && ((this.state.playlist && (this.state.playlist.contentCreators && this.state.playlist.contentCreators.length > 0) )|| (this.state.suggestedCreators && (this.state.suggestedCreators.length > 0))) &&
            <RelatedContent
              {...this.props}
              contentCreator={this.state.contentCreator}
              related={this.state.playlist ? this.state.playlist.contentCreators : this.state.suggestedCreators}
            />
          }
        </VCS.ViewContainer>
        {this.renderShareDialog()}
        {this.renderContactDialog()}
        {this.renderDrmDialog()}
        {this.renderProductDialog()}
        {this.renderCheckingPurchasesDialog()}
        {this.renderCountryBlockedDialog()}
      </React.Fragment>
    );
  };
}


const CreationItemContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
  float: left;
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`

const SocialButtons = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
  color: ${props => props.color};
  @media (max-width: 600px) {
    margin-bottom: 0;
    width: 100%;
    display: none;
  }
`;


const Profile = styled.div`
  background: url(${props => props.picture}) no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border: ${props => props.picture ? "1px solid black" : "none"};
  background-position-x: 50%;
  filter: blur(${props => props.blur ? "8px" : "0px"});
  -webkit-filter: blur(${props => props.blur ? "8px" : "0px"});
  top: 0;
  border-radius: 50%;
  margin-right: 30px;
  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;

const SocialButton = styled.div`
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  background-color: black;
  margin: 0 5px;
  @media (max-width: 600px) {
    top: 0;
    margin: 0 2px;
  }
`;