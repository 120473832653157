import React from 'react';
import styled from 'styled-components';
import { makeFitImageUrlWithSize, calculateImageStyle } from '../../utils';
import { get } from 'lodash';

const PersonCard = styled.div`
	width: 200px;
	height: 250px;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	transition: transform 0.2s;
	cursor: pointer;
	&:hover {
		transform: scale(1.02);
	}
`;

const PersonImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: ${props => {
		if (props.boundingBox) {
			const x = props.boundingBox.Left * 900;
			const y = props.boundingBox.Top * 100;
			return `${x}% ${y}%`;
		}
		return 'center';
	}};
	transform: ${props => {
		if (props.boundingBox) {
			// Calculamos el zoom basado en el tamaño del bounding box
			// Queremos que la cara ocupe aproximadamente el 40% de la altura
			const targetHeight = 0.3; // 40% de la altura total
			const scale = targetHeight / props.boundingBox.Height;
			// Limitamos el zoom entre 1x y 2x para evitar distorsión excesiva
			const clampedScale = Math.min(Math.max(scale, 1), 2);
			return `scale(${clampedScale})`;
		}
		return 'scale(1)';
	}};
	transform-origin: ${props => {
		if (props.boundingBox) {
			const x = props.boundingBox.Left * 90;
			const y = props.boundingBox.Top * 100;
			return `${x}% ${y}%`;
		}
		return 'center';
	}};
	background-color: #f0f0f0;
	transition: transform 0.3s ease;
`;

const PersonName = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 8px;
	color: white;
	font-size: 20px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
	background: linear-gradient(transparent, rgba(0,0,0,0.7));
    `;


const PersonItem = ({ person, onClick }) => {
	let boundingBox = get(person, 'mainImage.face_metadata');
	
	if (boundingBox) {
		boundingBox = JSON.parse(boundingBox).boundingBox;
	}
	return (
		<PersonCard onClick={onClick}>
			<PersonImage 
				src={makeFitImageUrlWithSize({image: person.mainImage}, 'xl')} 
				alt={person.name || 'Sin nombre'}
				boundingBox={boundingBox}
                style={calculateImageStyle(person.mainImage)}
			/>
			{person.name && (
				<PersonName>
					{person.name}
				</PersonName>
			)}
		</PersonCard>
	);
};

export default PersonItem;
